var timer = null;

function submitForm(node) {
  var $form = $(node).closest("form");
  var form = $form[0];
  console.log(node, "node");

  if ($form.hasClass("ajax")) {
    $.nette.ajax({
      url: form.action,
      method: form.method || "GET",
      dataType: "json",
      data: $form.serialize()
    });
  } else {
    $form.submit();
  }
}

$(document)
  .on(
    "change",
    "select[data-autosubmit], form[data-autosubmit] input, form[data-autosubmit] select",
    function() {
      submitForm(this);
    }
  )
  .on("click", "input[type=radio][data-autosubmit]", function() {
    submitForm(this);
  })
  .on(
    "keyup",
    "input[data-autosubmit], textarea[data-autosubmit], form[data-autosubmit] input, form[data-autosubmit] textarea",
    function() {
      clearTimeout(timer);
      timer = setTimeout(() => submitForm(this), 200);
    }
  );
