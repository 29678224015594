import Emitter from '../core/Emitter'
import { lang } from '../core/config'

const defaultState = {
    lessons: {},

    sets: {},

    rating: {
        sent: false,
        sentAll: false,
        hidden: false
    }
}

const LOCAL_STORAGE_KEY = `courseState-${lang}`
const DEFAULT_LOCAL_STORAGE_KEY = 'courseState-cs'

class CourseState extends Emitter {

    static courseDefaults = {
        completed: false,
        stars: 0
    };

    static setDefaults = {
        completed: false,
        rated: false
    };

    constructor() {
        super()

        // upgrade state without language support and move it to cs language
        const oldState = localStorage.getItem('courseState')
        if (oldState) {
            localStorage.removeItem('courseState')
            localStorage.setItem(DEFAULT_LOCAL_STORAGE_KEY, oldState)
        }

        const savedState = localStorage.getItem(LOCAL_STORAGE_KEY)
        if (savedState) {
            try {
                const state = JSON.parse(savedState)

                this.state = state
            } catch (error) {
                this.state = defaultState
            }
        } else {
            this.state = defaultState
        }

        // upgrade old state format
        if (!('sets' in this.state)) {
            this.state.sets = {}
        }

        if (!('rating' in this.state)) {
            this.state.rating = { ...defaultState.rating }
        } else {
            this.state.rating = {
                ...defaultState.rating,
                ...this.state.rating
            }
        }

        if (this.state.rating.sentAll) {
            this.state.rating.hidden = true
        }
    }

    get hasAllStars() {
        const entries = Object.entries(this.state.lessons)
        const stars = entries
            .map(entry => entry[1])
            .reduce((acc, lesson) => acc + lesson.stars, 0)

        return (stars === entries.length * 3)
    }

    get isCompleted() {
        const entries = Object.entries(this.state.lessons)
        const completed = entries.filter(entry => entry[1].completed)

        return completed.length === entries.length
    }

    commit() {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.state))
        this.emit('change', this.state)
    }

    updateLesson({ id, silent = false, ...data }) {
        if (id in this.state.lessons) {
            this.state.lessons[id] = {
                ...this.state.lessons[id],
                ...data
            }
        } else {
            this.state.lessons[id] = {
                ...CourseState.courseDefaults,
                ...data
            }
        }

        if (!silent) {
            this.commit()
        }
    }

    removeLesson({ id, silent = false }) {
        let deleted = null

        if (id in this.state.lessons) {
            deleted = this.state.lessons[id]
            delete this.state.lessons[id]
        }

        if (!silent) {
            this.commit()
        }

        return deleted
    }

    updateSet({id, silent = false, ...data}) {
        if (id in this.state.sets) {
            this.state.sets[id] = {
                ...this.state.sets[id],
                ...data
            }
        } else {
            this.state.sets[id] = {
                ...CourseState.setDefaults,
                ...data
            }
        }

        if (!silent) {
            this.commit()
        }
    }

    removeSet({ id, silent = false }) {
        let deleted = null

        if (id in this.state.sets) {
            deleted = this.state.sets[id]
            delete this.state.sets[id]
        }

        if (!silent) {
            this.commit()
        }

        return deleted
    }

    getLevel() {
        const entries = Object.entries(this.state.lessons)
        const courseCount = entries.length
        const stars = this.getStarCount()

        const levels = {
            min3: 95,
            min2: 76,
            min1: 50
        }

        const current = Math.ceil(stars/(courseCount * 3) * 100)

        let level = 0

        if (stars === courseCount * 3) {
            level = 4
        } else if (current >= levels.min3) {
            level = 3
        } else if (current >= levels.min2) {
            level = 2
        } else if (current >= levels.min1) {
            level = 1
        }

        return level
    }

    getStarCount() {
        const stars = Object.entries(this.state.lessons)
            .reduce((acc, entry) => acc + entry[1].stars, 0)

        return stars
    }
}

const state = new CourseState()

export default state