import * as ViewportServices from '../../services/Viewport';
import $ from 'jquery';

const options = {
    wrapClass: 'input',
    errorClass: 'input__error',
    wrapErrorClass: 'has-error'
}

document.addEventListener('nette:valid', event => {
    const element = event.target;

    if (element.tagName.toLowerCase() != 'button') {
        const errorElement = element.parentNode.querySelector('.InputError');
        if (errorElement) {
            element.parentNode.removeChild(errorElement);
        }
    }

    const errorElement = element.parentNode.querySelector('.' + options.errorClass);
    if (errorElement) {
        element.parentNode.removeChild(errorElement);
    }
    element.parentNode.classList.remove(options.wrapErrorClass);
    element.classList.add('is-valid');
});

document.addEventListener('nette:invalid', event => {
    const element = event.target;
    let errorElement = element.parentNode.querySelector('.' + options.errorClass);

    if (!errorElement) {
        errorElement = document.createElement('div');
        errorElement.className = options.errorClass;
    }

    errorElement.innerHTML = element.getAttribute('data-nette-error');
    setTimeout(function () {
        element.parentNode.classList.add(options.wrapErrorClass);
    }, 300);
    element.parentNode.appendChild(errorElement);
    element.classList.remove('is-valid');
});

document.addEventListener('nette:form:valid', event => {
    console.info('Form valid', event.target);
    const btn = event.target.querySelector('.btn');
    btn.classList.add('is-sending');
    btn.innerHTML = "";
    btn.addEventListener('click', function(event){
        event.preventDefault();
    });

});

document.addEventListener('nette:form:invalid', event => {
    const $form = $(event.target);
    const $error = $form.find('.' + options.wrapErrorClass);
    let $target = $error.closest('.' + options.wrapClass);
    ViewportServices.scrollToElement($target.length ? $target : $form, -120);
});