// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import './modernizr'
import 'console-polyfill';

import $ from 'jquery';
import Sticky from 'sticky-js'

// jquery utils
import 'jquery.easing';

import './plugins/nette.ajax';
import './plugins/giant.trigger';
import './plugins/giant.autosubmit';


// other
import './plugins/flickity.ios13fix.js'

import './modules/antispam';

// nette
import './nette/NetteValidationMessages';
import './nette/NetteSnippets';

import Nette from './nette/NetteForms';
window.Nette = Nette;
Nette.initOnLoad();

if ($.nette) {
    $.nette.init();
}

import TouchMouseInputResolver from './meta/TouchMouseInputResolver'
new TouchMouseInputResolver()

import './meta/CourseState'

import { loadComponents } from './core/componentUtils';
loadComponents();

var sticky = new Sticky('[data-sticky]', { "marginTop": 90 });