import $ from 'jquery';

const $viewport = $('meta[name=viewport]');
$viewport.data('fixed', false);
const VIEWPORT_DEFAULT_VALUE = $viewport.attr('content');
const VIEWPORT_FIXED_VALUE = 'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1';
const $root = $('html');

export const IS_ACTIVE = true;

export function scrollToElement(element, offset = 0, callback = null) {
    if (!(element instanceof  jQuery)) {
        element = $(element);
    }

    if (!element.length) {
        console.warn('Element ', element, ' nebyl nalezen.');
        return;
    }

    scrollTo(element.offset().top, offset, callback);
}

// @FIXME scroll pres service nebo Site??
export function scrollTo(top = 0, offset = 0, callback = null) {
    $('html, body').animate({
        scrollTop: top + offset
    }, {
        easing: 'easeInOutSine',
        duration: 800,
        complete: () => {
            if (typeof callback === 'function') {
                callback();
            }
        }
    });
}

export function disableScrolling() {
    if (!$viewport.data('fixed')) {
        $root.addClass('has-disable-scrolling');
        $viewport.data('fixed', true);
        $viewport.attr('content', VIEWPORT_FIXED_VALUE);
    }
}

export function enableScrolling() {
    $root.removeClass('has-disable-scrolling');
    $viewport.attr('content', VIEWPORT_DEFAULT_VALUE);
    $viewport.data('fixed', false);
}

export function disableTouchMove(exceptSelector) {
    var $except = $(exceptSelector);

    $(window).on('touchmove.siteTouchMove', function(event) {
        if (!$except.is(event.target) && $except.has(event.target).length === 0) {
            event.preventDefault();
        }
    });
}

export function enableTouchMove() {
    $(window).off('.siteTouchMove');
}

function toggleVisibleContentOnMobile() {
    $("[data-toggle-visibility]").click(function(event){
        event.preventDefault();
        $("[data-toggle-visibility]").toggleClass("show");
        $(this).closest(".grid-item").find("[data-toggle-content]").toggleClass("show");
    });
}

toggleVisibleContentOnMobile();

