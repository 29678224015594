$(document).on('click', '[data-trigger]', function(event) {
    const $target = $(this);
    let dataset = this.dataset;

    const action = $target.data('trigger');
    const value = $target.data('trigger-value');

    let data = Object.keys(dataset)
        .filter(key => key !== 'trigger' && key.match(/^trigger/))
        .reduce((data, key) => {
            let id = key.replace('trigger', '');
            id = id.charAt(0).toLowerCase() + id.slice(1);
            try {
                data[id] = JSON.parse(dataset[key]);
            } catch (e) {
                data[id] = dataset[key];
            }
            return data;
        }, {});

    console.group('Trigger');
    console.log('action: ', action);
    console.log('data: ', data);
    console.groupEnd();

    let canTrigger = !!action;

    if (data.selfOnly && event.target !== event.currentTarget) {
        canTrigger = false;
    }

    if (canTrigger) {
        event.preventDefault();
        $target.trigger(action, typeof value !== 'undefined' ? value : data);
    }
});